<template>
  <div class="custom-bg-secondary">
    <b-breadcrumb
      style="margin-bottom: 0; border-radius: 0"
      v-if="type !== 'contact'"
      class="pash-breadcrumb"
    >
      <div class="container mx-auto d-flex py-0">
        <b-breadcrumb-item :to="{ name: 'WorkwithUs' }">
          <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
          {{ $t("Contact.WorkWithPash") }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active v-if="type === 'inspection-agents'"
          >{{ $t("WorkWithUs.VehicleInspector") }}</b-breadcrumb-item
        >
        <b-breadcrumb-item active v-else-if="type === 'sales-representatives'"
          >{{ $t("Contact.ContactUs.SalesRepresentatives") }}</b-breadcrumb-item
        >
        <b-breadcrumb-item active v-else-if="type === 'freight-forwarders'"
          >{{ $t("WorkWithUs.FreightForwarders") }}</b-breadcrumb-item
        >
        <b-breadcrumb-item active v-else-if="type === 'customs-brokers'"
          >{{ $t("WorkWithUs.CustomsBrokers") }}</b-breadcrumb-item
        >
        <b-breadcrumb-item active v-else-if="type === 'jobs'">{{ $t("WorkWithUs.Jobs") }}</b-breadcrumb-item>
      </div>
    </b-breadcrumb>

    <div
      :class="{
        contactBG: type == 'contact',
        inspectionBG: type == 'inspection-agents',
        representativesBG: type == 'sales-representatives',
        freightBG: type == 'freight-forwarders',
        customersBG: type == 'customs-brokers',
        jobsBG: type == 'jobs',
      }"
    >
      <b-container class="fromDiv mx-auto">
        <div v-if="type == 'contact'" class="text-white">
          <h2>{{ $t("Contact.ContactUs.ContactUs") }}</h2>
          <div class="line"></div>
          <h3 class="contactus-text">
            {{
              $t("Contact.ContactUs.HeaderQuestion") +
              $t("Contact.ContactUs.HeaderQuestion2")
            }}
          </h3>
        </div>

        <div v-if="type != 'contact' && type != 'jobs'" class="text-white title-style">
          {{ $t("Contact.WantToWork") }}
          <span v-if="type == 'inspection-agents'"
            ><!--{{ $t("Contact.an") }}-->
            {{ $t("WorkWithUs.InspectionAgents") }}
            <InfoIcon v-b-modal.workWithPashText style="cursor: pointer" />
          </span>
          <span v-if="type == 'freight-forwarders'"
            ><!--{{ $t("Contact.a") }}-->
            {{ $t("WorkWithUs.FreightForwarders") }}
            <InfoIcon v-b-modal.workWithPashText style="cursor: pointer" />
          </span>
          <span v-if="type == 'customs-brokers'"
            ><!--{{ $t("Contact.a") }}-->
            {{ $t("WorkWithUs.CustomsBrokers") }}
            <InfoIcon v-b-modal.workWithPashText style="cursor: pointer" />
          </span>
          <span v-if="type == 'sales-representatives'" class="text-white"
            ><!--{{ $t("Contact.a") }}-->
            {{ $t("WorkWithUs.SalesRepresentatives") }}
            <InfoIcon v-b-modal.workWithPashText style="cursor: pointer" />
          </span>
        </div>
        <p v-if="type == 'jobs'" class="text-white title-style">
          {{ $t("Contact.Job.InterestedCareer1") }}
          {{ $t("Contact.Job.InterestedCareer2") }}
        </p>
        <b-modal id="workWithPashText" hide-footer centered no-stacking size="lg">
          <b-card>
            <b-card-body>
              <div v-if="type == 'contact'">
                <h5>{{ $t("Contact.ContactUs.SendUsEmail") }}</h5>
                <p class="mt-3">
                  <a
                    :href="'mailto:' + $t('Contact.ContactUs.SendUsEmailAddress')"
                    class="h5"
                    >{{ $t("Contact.ContactUs.SendUsEmailAddress") }}</a
                  >
                </p>
              </div>
              <div v-if="type != 'contact'">
                <div v-if="isIA">
                  <h4>{{ $t("Contact.InspectionAgent.WorkWithPash") }}</h4>
                  <p>
                    {{ $t("Contact.InspectionAgent.WorkWithPashDescription") }}
                  </p>
                </div>
                <div v-if="isFF">
                  <h4>{{ $t("Contact.FreightForwarder.WorkWithPash") }}</h4>
                  <p>
                    {{ $t("Contact.FreightForwarder.WorkWithPashDescription") }}
                  </p>
                </div>
                <div v-if="isCB">
                  <h4>{{ $t("Contact.CustomsBroker.WorkWithPash") }}</h4>
                  <p>
                    {{ $t("Contact.CustomsBroker.WorkWithPashDescription") }}
                  </p>
                </div>
                <div v-if="isSR">
                  <h4>{{ $t("Contact.SalesRepresentative.WorkWithPash") }}</h4>
                  <p>
                    {{ $t("Contact.SalesRepresentative.WorkWithPashDescription") }}
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-modal>
        <div
          class="forms-wrapper"
          :class="type === 'jobs' || type === 'contact' ? 'align-items-start' : ''"
        >
          <div class="form-column">
            <b-overlay :show="sending" rounded="lg">
              <b-card class="mb-6 mb-md-0">
                <h5 v-if="type == 'jobs'">
                  {{ $t("Contact.Job.SubmitCV") }}
                </h5>
                <h5 v-else>{{ $t("Contact.FillOutForm") }}</h5>

                <p>{{ $t("Contact.ConnectYouSoon") }}</p>
                <b-form class="mt-4 pt-2" id="contactForm" @submit.prevent="handleForm()">
                  <b-form-group>
                    <label for="txtFirstName">{{
                      $t("contactInfo.label.firstName")
                    }}</label>
                    <b-form-input
                      type="text"
                      id="txtFirstName"
                      v-model="form.firstName"
                      :state="form.firstName ? true : null"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="txtLastName">{{
                      $t("contactInfo.label.lastName")
                    }}</label>
                    <b-form-input
                      type="text"
                      id="txtLastName"
                      v-model="form.lastName"
                      :state="form.lastName ? true : null"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="txtEmail">{{
                      $t("contactInfo.label.emailAddress")
                    }}</label>
                    <b-form-input
                      type="email"
                      id="txtEmail"
                      v-model="form.email"
                      :state="form.email ? emailFormat.test(form.email) : null"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="ddlCountry">{{ $t("contactInfo.label.country") }}</label
                    ><b-form-select id="ddlCountry" v-model="form.country">
                      <b-form-select-option>{{
                        $t("contactInfo.label.selectCountry")
                      }}</b-form-select-option>
                      <b-form-select-option
                        v-for="country in $store.getters.countries"
                        :key="country.Name"
                        :value="country.Code"
                        >{{ country.Name }}</b-form-select-option
                      >
                    </b-form-select>
                    <div style="margin-top: -30px; width: 28px; float: right">
                      <b-icon
                        v-if="form.country"
                        icon="check"
                        variant="success"
                        scale="2"
                        class="mt-n3 mr-2"
                      />
                      <b-icon
                        v-else
                        icon="caret-down-fill"
                        variant="primary"
                        class="mt-n3 mr-2"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <label for="txtPhone"
                      >{{ $t("contactInfo.label.phoneNumber") }}
                    </label>
                    <!-- <b-form-input type="tel" id="txtPhone" v-model="form.phone" /> -->

                    <b-input-group class="row m-0">
                      <VuePhoneNumberInput
                        class="col p-0"
                        v-model="form.phone"
                        show-code-on-list
                        :translations="{
                          countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                          countrySelectorError: $t('Contact.countrySelectorError'),
                          phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                          example: $t('Contact.example'),
                        }"
                        :default-country-code="
                          $store.getters.country ? $store.getters.country.Code : 'CA'
                        "
                        @update="setMobilePayload($event)"
                      />

                      <!-- <b-input-group-append>
                          <div
                            class="right-rounded border border-left-0 px-3"
                            :class="validMobile ? 'border-success' : ''"
                          ></div>
                        </b-input-group-append> -->
                    </b-input-group>
                    <div style="margin-top: -31px; width: 28px; float: right">
                      <b-icon
                        v-if="validMobile"
                        icon="check"
                        variant="success"
                        scale="2"
                        class="mt-n3 mr-2"
                      ></b-icon>
                    </div>
                  </b-form-group>

                  <b-form-group v-if="type == 'jobs'">
                    <label for="ddlSubject">{{ $t("Contact.Jobs.JobsCategory") }}</label
                    ><b-form-select id="ddlJobCategory" v-model="form.category">
                      <b-form-select-option disabled>
                        {{ $t("Contact.Jobs.SelectJobsCategory") }}
                      </b-form-select-option>
                      <b-form-select-option
                        v-for="category in jobsCategory"
                        :key="category"
                        :value="category"
                        >{{ $t(`Contact.${category}`) }}</b-form-select-option
                      >
                    </b-form-select>
                    <div
                      style="margin-top: -30px; width: 28px; float: right; z-index: -99"
                    >
                      <b-icon
                        v-if="form.category"
                        icon="check"
                        variant="success"
                        scale="2"
                        class="mt-n3 mr-2"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="caret-down-fill"
                        variant="primary"
                        class="mt-n3 mr-2"
                      ></b-icon>
                    </div>
                  </b-form-group>

                  <b-form-group v-else>
                    <label for="ddlSubject">{{ $t("Contact.Subject") }}</label
                    ><b-form-select id="ddlSubject" v-model="form.subject">
                      <b-form-select-option disabled>
                        <!-- {{ $t("Contact.SelectSubject") }} -->
                      </b-form-select-option>
                      <b-form-select-option
                        v-for="subject in subjects"
                        :key="subject"
                        :value="subject"
                        >{{ $t(`Contact.${subject}`) }}</b-form-select-option
                      >
                    </b-form-select>
                    <div style="margin-top: -30px; width: 28px; float: right">
                      <b-icon
                        v-if="form.subject"
                        icon="check"
                        variant="success"
                        scale="2"
                        class="mt-n3 mr-2"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="caret-down-fill"
                        variant="primary"
                        class="mt-n3 mr-2"
                      ></b-icon>
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <label for="txtMessage">Message</label>
                    <b-form-textarea
                      id="txtMessage"
                      v-model="form.message"
                    ></b-form-textarea>
                  </b-form-group>

                  <div v-if="type == 'jobs'">
                    <div class="attach-box">
                      <label>{{ $t("Contact.Job.AttachCV") }}</label>
                      <div class="upload-button">
                        Select a file
                        <b-form-file v-model="cv" class="mt-3" plain></b-form-file>
                      </div>
                      <!-- <div class="mt-3">Selected file: {{ cv ? cv.name : '' }}</div> -->
                    </div>
                  </div>

                  <section
                    class="border border-danger mt-3 text-center p-0"
                    v-show="errorMessage"
                  >
                    <b class="text-danger">{{ errorMessage }}</b>
                  </section>

                  <div class="pt-6">
                    <b-btn type="submit" variant="primary" class="w-100">
                      {{ $t("global.button.submit") }}
                    </b-btn>
                  </div>
                </b-form>
              </b-card>
            </b-overlay>
          </div>
          <div v-if="type == 'jobs' || type == 'contact'" class="form-column">
            <div v-if="type == 'jobs'">
              <b-card class="mb-5 form-column w-100 career">
                <h4>{{ $t("Contact.Job.CareerOpportunities") }}</h4>

                <p>{{ $t("Contact.Job.SeekingTalents") }}</p>
                <ul>
                  <li>{{ $t("Contact.JobList.1") }}</li>
                  <li>{{ $t("Contact.JobList.2") }}</li>
                  <li>{{ $t("Contact.JobList.3") }}</li>
                  <li>{{ $t("Contact.JobList.4") }}</li>
                  <li>{{ $t("Contact.JobList.5") }}</li>
                </ul>
              </b-card>
              <MENU :title="$t('Contact.OtherWaysToWorkWithPash')" />
            </div>

            <div v-else-if="type == 'contact'">
              <b-card class="mb-6 mb-md-4">
                <h5 class="mb-4">{{ $t("Contact.ContactUs.SendUsEmail") }}</h5>
                <a href="mailto:contact@pashmotors.com" target="_blank">
                  <img src="@/assets/img/icons/envelop.svg" alt="" />
                  contact@pashmotors.com
                </a>
              </b-card>
              <b-card v-if="type == 'contact'">
                <h5 class="mb-4">
                  {{ $t("Contact.ContactUs.SalesRepresentatives") }}
                </h5>
                <p class="mb-6">
                  {{ $t("Contact.ContactUs.SalesRepresentativesDescription") }}
                </p>
                <!-- <p class="mt-4">
                  <router-link :to="{name: 'ContactReps'}" class="btn btn-primary"
                    >Find your Sales Agent now</router-link
                  >
                </p> -->
                <div>
                  <b-btn
                    pill
                    variant="primary"
                    :to="{ name: 'ContactReps' }"
                    class="px-4 find-agent-btn"
                  >
                    {{ $t("Contact.ContactUs.FindSalesAgent") }}
                  </b-btn>
                </div>
              </b-card>
            </div>
          </div>
          <div align-self="end" v-else class="form-column">
            <MENU :title="$t('Contact.OtherWaysToWorkWithPash')" />
          </div>
        </div>
        <b-card class="faq" v-if="type !== 'jobs' && type !== 'contact'"
          ><h5 class="">
            {{ $t("Contact.Question.Title") }}
            <span v-show="isIA">{{ $t("WorkWithUs.InspectionAgents") }}</span>
            <span v-show="isFF">{{ $t("WorkWithUs.FreightForwarders") }}</span>
            <span v-show="isCB">{{ $t("WorkWithUs.CustomsBrokers") }}</span>
            <span v-show="isSR">{{ $t("WorkWithUs.SalesRepresentatives") }}</span>
          </h5>
          <b-row>
            <b-col cols="12">
              <div class="border-bottom faq-box">
                <p v-b-toggle.q-1>
                  <span v-show="isIA">{{
                    $t("Contact.InspectionAgent.Question.1")
                  }}</span>
                  <span v-show="isFF">{{
                    $t("Contact.FreightForwarder.Question.1")
                  }}</span>
                  <span v-show="isCB">{{ $t("Contact.CustomsBroker.Question.1") }}</span>
                  <span v-show="isSR">{{
                    $t("Contact.SalesRepresentative.Question.1")
                  }}</span>
                  <span class="when-opened float-right">
                    <b-icon-chevron-up />
                  </span>
                  <span class="when-closed float-right">
                    <b-icon-chevron-down />
                  </span>
                </p>
                <b-collapse id="q-1">
                  <p>
                    <span v-show="isIA">{{
                      $t("Contact.InspectionAgent.Answer.1")
                    }}</span>
                    <span v-show="isFF">{{
                      $t("Contact.FreightForwarder.Answer.1")
                    }}</span>
                    <span v-show="isCB">{{ $t("Contact.CustomsBroker.Answer.1") }}</span>
                    <span v-show="isSR">{{
                      $t("Contact.SalesRepresentative.Answer.1")
                    }}</span>
                  </p>
                </b-collapse>
              </div>

              <div class="border-bottom faq-box">
                <p v-b-toggle.q-2>
                  <span v-show="isIA">{{
                    $t("Contact.InspectionAgent.Question.2")
                  }}</span>
                  <span v-show="isFF">{{
                    $t("Contact.FreightForwarder.Question.2")
                  }}</span>
                  <span v-show="isCB">{{ $t("Contact.CustomsBroker.Question.2") }}</span>
                  <span v-show="isSR">{{
                    $t("Contact.SalesRepresentative.Question.2")
                  }}</span>
                  <span class="when-opened float-right">
                    <b-icon-chevron-up />
                  </span>
                  <span class="when-closed float-right">
                    <b-icon-chevron-down />
                  </span>
                </p>
                <b-collapse id="q-2">
                  <p>
                    <span v-show="isIA">{{
                      $t("Contact.InspectionAgent.Answer.2")
                    }}</span>
                    <span v-show="isFF">{{
                      $t("Contact.FreightForwarder.Answer.2")
                    }}</span>
                    <span v-show="isCB">{{ $t("Contact.CustomsBroker.Answer.2") }}</span>
                    <span v-show="isSR">{{
                      $t("Contact.SalesRepresentative.Answer.2")
                    }}</span>
                  </p>
                </b-collapse>
              </div>

              <div class="border-bottom faq-box">
                <p v-b-toggle.q-3>
                  <span v-show="isIA">{{
                    $t("Contact.InspectionAgent.Question.3")
                  }}</span>
                  <span v-show="isFF">{{
                    $t("Contact.FreightForwarder.Question.3")
                  }}</span>
                  <span v-show="isCB">{{ $t("Contact.CustomsBroker.Question.3") }}</span>
                  <span v-show="isSR">{{
                    $t("Contact.SalesRepresentative.Question.3")
                  }}</span>
                  <span class="when-opened float-right">
                    <b-icon-chevron-up />
                  </span>
                  <span class="when-closed float-right">
                    <b-icon-chevron-down />
                  </span>
                </p>
                <b-collapse id="q-3">
                  <p>
                    <span v-show="isIA">{{
                      $t("Contact.InspectionAgent.Answer.3")
                    }}</span>
                    <span v-show="isFF">{{
                      $t("Contact.FreightForwarder.Answer.3")
                    }}</span>
                    <span v-show="isCB">{{ $t("Contact.CustomsBroker.Answer.3") }}</span>
                    <span v-show="isSR">{{
                      $t("Contact.SalesRepresentative.Answer.3")
                    }}</span>
                  </p>
                </b-collapse>
              </div>
              <div class="border-bottom faq-box">
                <p v-b-toggle.q-4>
                  <span v-show="isIA">{{
                    $t("Contact.InspectionAgent.Question.4")
                  }}</span>
                  <span v-show="isFF">{{
                    $t("Contact.FreightForwarder.Question.4")
                  }}</span>
                  <span v-show="isCB">{{ $t("Contact.CustomsBroker.Question.4") }}</span>
                  <span v-show="isSR">{{
                    $t("Contact.SalesRepresentative.Question.4")
                  }}</span>
                  <span class="when-opened float-right">
                    <b-icon-chevron-up />
                  </span>
                  <span class="when-closed float-right">
                    <b-icon-chevron-down />
                  </span>
                </p>
                <b-collapse id="q-4">
                  <p>
                    <span v-show="isIA">{{
                      $t("Contact.InspectionAgent.Answer.4")
                    }}</span>
                    <span v-show="isFF">{{
                      $t("Contact.FreightForwarder.Answer.4")
                    }}</span>
                    <span v-show="isCB">{{ $t("Contact.CustomsBroker.Answer.4") }}</span>
                    <span v-show="isSR">{{
                      $t("Contact.SalesRepresentative.Answer.4")
                    }}</span>
                  </p>
                </b-collapse>
              </div>

              <div class="border-bottom faq-box">
                <p v-b-toggle.q-5>
                  <span v-show="isIA">{{
                    $t("Contact.InspectionAgent.Question.5")
                  }}</span>
                  <span v-show="isFF">{{
                    $t("Contact.FreightForwarder.Question.5")
                  }}</span>
                  <span v-show="isCB">{{ $t("Contact.CustomsBroker.Question.5") }}</span>
                  <span v-show="isSR">{{
                    $t("Contact.SalesRepresentative.Question.5")
                  }}</span>
                  <span class="when-opened float-right">
                    <b-icon-chevron-up />
                  </span>
                  <span class="when-closed float-right">
                    <b-icon-chevron-down />
                  </span>
                </p>
                <b-collapse id="q-5">
                  <p>
                    <span v-show="isIA">{{
                      $t("Contact.InspectionAgent.Answer.5")
                    }}</span>
                    <span v-show="isFF">{{
                      $t("Contact.FreightForwarder.Answer.5")
                    }}</span>
                    <span v-show="isCB">{{ $t("Contact.CustomsBroker.Answer.5") }}</span>
                    <span v-show="isSR">{{
                      $t("Contact.SalesRepresentative.Answer.5")
                    }}</span>
                  </p>
                </b-collapse>
              </div>

              <div class="border-bottom faq-box">
                <p v-b-toggle.q-6>
                  <span v-show="isIA">{{
                    $t("Contact.InspectionAgent.Question.6")
                  }}</span>
                  <span v-show="isFF">{{
                    $t("Contact.FreightForwarder.Question.6")
                  }}</span>
                  <span v-show="isCB">{{ $t("Contact.CustomsBroker.Question.6") }}</span>
                  <span v-show="isSR">{{
                    $t("Contact.SalesRepresentative.Question.6")
                  }}</span>
                  <span class="when-opened float-right">
                    <b-icon-chevron-up />
                  </span>
                  <span class="when-closed float-right">
                    <b-icon-chevron-down />
                  </span>
                </p>
                <b-collapse id="q-6">
                  <p>
                    <span v-show="isIA">{{
                      $t("Contact.InspectionAgent.Answer.6")
                    }}</span>
                    <span v-show="isFF">{{
                      $t("Contact.FreightForwarder.Answer.6")
                    }}</span>
                    <span v-show="isCB">{{ $t("Contact.CustomsBroker.Answer.6") }}</span>
                    <span v-show="isSR">{{
                      $t("Contact.SalesRepresentative.Answer.6")
                    }}</span>
                  </p>
                </b-collapse>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
      <b-modal
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        centered
        id="messageSendSuccess"
      >
        <template #default="{ hide }">
          <div>
            <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
          </div>
          <div class="text-center m-3">
            <h3>{{ $t("Contact.MessageSuccess") }}</h3>

            <div class="mt-4">
              <b-link @click="$router.go(0)">
                {{ $t("Contact.BackLinkText") }}
              </b-link>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import axios from "axios";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// import emailjs from "@emailjs/browser";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6Lcb1qYiAAAAAAnFo885hqpkVQ3nRx8VyIFQpqhc" });
import InfoIcon from "vue-material-design-icons/InformationOutline";
import parsePhoneNumber from "libphonenumber-js";

export default {
  components: {
    InfoIcon,
    VuePhoneNumberInput,
    MENU: () => import("@/components/global/TheContactMenu.vue"),
  },
  watch: {
    type: function () {
      document.title = `${document.title} | ${this.type.replace("-", " ")}`;
    },
  },
  props: {},
  computed: {
    type() {
      //returns the url after pash.com
      return this.$route.params.type;
    },
    isIA() {
      return this.type === "inspection-agents";
    },
    isFF() {
      return this.type === "freight-forwarders";
    },
    isCB() {
      return this.type === "customs-brokers";
    },
    isSR() {
      return this.type === "sales-representatives";
    },
    validMobile() {
      if (this.mobilePayload) {
        if (this.mobilePayload.isValid) {
          return true;
        }
      }
      return false;
    },
  },
  data() {
    return {
      form: {},
      subjects: [
        "GeneralInquiry",
        "Comment",
        "WorkWithPashLB",
        "Partnerships",
        "WebsiteIssues",
        "Complaint",
        "BuyCars",
        "SellCars",
        "Other",
      ],
      jobsCategory: [
        "SoftwareDevelopment",
        "WebDesign",
        "Marketing",
        "LogisticsOperations",
        "CustomerService",
        "Other",
      ],
      emailFormat: /\S+@\S+\.\S+/,
      mobilePayload: null,
      cv: null,
      errorMessage: null,
      sharing: {
        url: "https://contact@pashmotors.com",
        title: "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description: "Brand new development setup created by Evan You.",
        quote: "The hot reload is so fast it's near instant. - Evan You",
      },
      sending: false,
      text: null,
      pageType: {
        contact: 0,
        "inspection-agents": 1,
        "freight-forwarders": 2,
        "customs-brokers": 3,
        "sales-representatives": 4,
        jobs: 5,
      },
    };
  },
  methods: {
    setMobilePayload(event) {
      if(!event.phoneNumber) return false
      const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);
      if(mobileNumber) {
        const payload = {
          ...mobileNumber,
          isValid: mobileNumber ? mobileNumber.isValid() : false,
          countryCallingCode: mobileNumber.countryCallingCode,
          formatInternational: mobileNumber.formatInternational(),
          formatNational: mobileNumber.number,
          formattedNumber: mobileNumber.number,
          uri: mobileNumber.getURI(),
          e164: mobileNumber.ext
        }
        this.mobilePayload = payload;
      }
    },
    handleForm() {
      if (!this.form.firstName || !this.form.lastName)
        this.errorMessage = "please enter your name";
      else if (!this.form.email) this.errorMessage = "please enter your email";
      else if (!this.form.phone) this.errorMessage = "please enter your phone number";
      else if (this.type !== "jobs" && !this.form.subject)
        this.errorMessage = "please select a subject";
      else if (this.type === "jobs" && !this.form.category)
        this.errorMessage = "please select a category";
      else if (!this.form.message) this.errorMessage = "Message missing";
      else {
        this.sending = true;
        this.$recaptcha("login")
          .then(() => {
            axios
              .post("Basic/AddContactForm", {
                Name: `${this.form.firstName} ${this.form.lastName}`,
                Email: this.form.email,
                CountryCode: this.form.country,
                Mobile: this.mobilePayload.formattedNumber,
                Subject: this.form.subject,
                Message: this.form.message,
                TypeId: this.pageType[this.type],
              })
              .then(() => {
                this.sending = false;
                this.$bvModal.show("messageSendSuccess");
              })
              .catch((error) => {
                this.errorMessage = error.toString();
                this.sending = false;
              })
              .finally(() => {
                this.clearData();
              });
          })
          .catch(() => {
            this.$store.dispatch("showToast", {
              content: "Captcha Error, Please Try Again!",
              type: "error",
            });
          })
          .finally(() => (this.sending = false));
        /*
        emailjs
          .send(
            "service_pash",
            "template_contactUs",
            {
              pageName: this.type,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              email: this.form.email,
              phone: this.form.phone,
              country: this.form.country,
              subject: this.form.subject,
              category: this.form.category,
              message: this.form.message,
              cv: this.cv
            },
            "0gP81m4WA_7oLS0xq"
          )
          .then(
            result => {
              this.clearData();
              this.sending = false;
              this.$bvModal.show("messageSendSuccess");
              console.log("SUCCESS!", result.text);
            },
            error => {
              this.errorMessage = error.text;
              this.sending = false;
              console.log("FAILED...", error.text);
            }
          );
*/
      }
    },
    clearData() {
      this.form = {};
      this.errorMessage = null;
      this.cv = null;
    },
  },
  mounted() {
    document.title = `${document.title} | ${this.type.replace("-", " ")}`;
  },
  beforeMount() {
    if (this.$store.getters.country) {
      this.form.country = this.$store.getters.country.Code;
    }
    this.$recaptchaInstance.showBadge();
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style scoped lang="scss">
.contactBG {
  background-image: url("~@/assets/img/contactUs_bg.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 645px;
  @media (max-width: 991px) {
    background-size: 100% 365px;
  }
  @media (max-width: 575px) {
    background-size: 100% 330px;
  }
}
.freightBG,
.inspectionBG,
.representativesBG,
.customersBG {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 930px;
  @media (max-width: 991px) {
    background-size: 100% 625px;
  }
  @media (max-width: 767px) {
    background-size: 100% 520px;
  }
  @media (max-width: 575px) {
    background-size: 150% 470px;
  }
  .fromDiv {
    @media (max-width: 767px) {
      padding-top: 300px;
    }
  }
}
.inspectionBG {
  background-image: url("~@/assets/img/inspectionAgent_bg.jpg");
  @media (max-width: 575px) {
    background-position: -140px 0px;
  }
}
.representativesBG {
  background-image: url("~@/assets/img/salesrep_bg.jpg");
  @media (max-width: 575px) {
    background-position: -140px 0px;
  }
}
.freightBG {
  background-image: url("~@/assets/img/freight.png");
}
.customersBG {
  background-image: url("~@/assets/img/customers.png");
}
.jobsBG {
  background-image: url("~@/assets/img/jobs.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 540px;
  @media (max-width: 991px) {
    background-size: 100% 450px;
  }
  @media (max-width: 767px) {
    background-size: 100% 465px;
  }
  @media (max-width: 575px) {
    background-size: 150% 290px;
  }
  .fromDiv {
    @media (max-width: 767px) {
      padding-top: 300px;
    }
    @media (max-width: 575px) {
      padding-top: 160px;
    }
  }
}
.fromDiv {
  padding-top: 160px;
  padding-bottom: 150px;
  margin-left: 105px;
  margin-right: 105px;
  @media (max-width: 1200px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (max-width: 991px) {
    padding-top: 82px;
  }
  @media (max-width: 575px) {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 100px;
  }
  h2 {
    @media (max-width: 991px) {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
    }
    @media (max-width: 575px) {
      font-size: 24px;
    }
  }
}
.line {
  height: 6px;
  width: 163px;
  background: white;
  margin-bottom: 146px;
  position: relative;
  top: 5px;
  border-radius: 5px;
  @media (max-width: 991px) {
    margin-bottom: 55px;
    height: 4px;
    width: 115px;
  }
  @media (max-width: 575px) {
    top: -5px;
  }
}
.directory {
  margin-bottom: 0;
  border-radius: 0;
}
.title-style {
  margin-top: 131px;
  width: 50%;
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  font-family: "Poppins", sans-serif !important;
}

@media (max-width: 991px) {
  .fromDiv {
    padding-bottom: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .line {
    margin-bottom: 50px;
  }
  .title-style {
    font-size: 28px;
    line-height: 40px;
    width: 60%;
  }
}
@media (max-width: 767px) {
  .title-style {
    margin-top: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    font-family: "Inter", sans-serif !important;
  }
}

@media (max-width: 575px) {
  .line {
    height: 2px;
    width: 72px;
    margin-bottom: 35px;
  }

  .title-style {
    margin-top: 0;
    line-height: 28px;
    width: 100%;
  }
}
.dotted-line {
  border-bottom: 1px dotted;
}
.fromDiv {
  h3 {
    font-size: 34px !important;
    line-height: 48px !important;
    @media (max-width: 991px) {
      font-size: 24px !important;
      line-height: 32px !important;
    }
    @media (max-width: 575px) {
      font-size: 20px !important;
      line-height: 30px !important;
    }
  }
}
.forms-wrapper {
  margin-top: 40px;
  display: flex;
  column-gap: 24px;
  align-items: flex-end;
  @media (max-width: 991px) {
    column-gap: 16px;
    margin-top: 56px;
    align-items: center;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 24px;
  }
  @media (max-width: 575px) {
    margin-top: 16px;
  }
}
.form-column {
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    h5 {
      font-size: 20px;
    }
  }
  @media (max-width: 575px) {
    h5 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .card-body {
    padding: 40px 64px;
    @media (max-width: 991px) {
      padding: 24px;
    }
    @media (max-width: 575px) {
      padding: 24px 16px;
    }
  }
  input,
  select {
    border-radius: 35px;
    height: 48px;
  }
  textarea {
    border-radius: 4px;
    height: 135px;
    resize: none;
  }
  .btn {
    border-radius: 40px;
    height: 64px;
    font-size: 20px;
    @media (max-width: 1200px) {
      height: 48px;
      font-size: 16px;
    }
  }
  .form-group {
    margin-bottom: 24px;
  }
  .find-agent-btn {
    min-width: 340px;
    display: inline-flex !important;
    @media (max-width: 1200px) {
      min-width: 100%;
    }
  }
}
.faq {
  margin-top: 64px;
  @media (max-width: 960px) {
    margin-top: 16px;
  }
  @media (max-width: 767px) {
    margin-top: 24px;
  }
  .card-body {
    padding: 40px 24px;
    @media (max-width: 575px) {
      padding: 16px;
    }
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
    @media (max-width: 575px) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
  .faq-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 575px) {
      font-size: 14px;
    }
    &:first-child {
      border-top: 1px solid #dee2e6;
    }
    p {
      margin-bottom: 16px;
      width: 100%;
      padding: 0 16px;
      &.collapsed,
      &.not-collapsed {
        min-height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
      }
    }
    div {
      width: 100%;
    }
  }
}
.career {
  h4 {
    margin-bottom: 24px;
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }
  li {
    position: relative;
    padding-left: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      background: #006ac3;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.attach-box {
  height: 100px;
  border-radius: 4px;
  background: #e6f0f9;
  padding: 16px 24px;
  label {
    display: block;
    width: 100%;
  }
}
.upload-button {
  min-width: 100px;
  height: 32px;
  background: #d0d0d0;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.contactus-text {
  width: 630px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    font-weight: 600;
    font-family: "Inter", sans-serif !important;
  }
}
</style>

<style lang="scss">
.form-column {
  .input-tel {
    input {
      border-radius: 35px !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .country-selector {
    border: none !important;
    height: 48px;
    position: relative;
    width: 100px;
    input {
      display: flex;
      align-items: center;
      padding: 0 0 0 48px !important;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 33px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: #d0d0d0;
    }
  }
  .country-selector__input {
    height: 100%;
  }
  .vue-phone-number-input {
    border-radius: 35px !important;
    height: 48px !important;
    border: 1px solid #ccc !important;
    input {
      box-shadow: none !important;
      outline: none !important;
      border: none !important;
      background: none !important;
      height: 48px;
    }
  }
  .country-selector__label {
    display: none !important;
  }
  .country-selector__country-flag {
    top: 18px !important;
    left: 20px !important;
  }
  svg {
    display: none !important;
  }
}
.pash-breadcrumb {
  position: sticky;
  top: 109px;
  z-index: 99;
  background: #eff1f3 !important;
  @media (max-width: 991px) {
    top: 127px;
  }
}
</style>
